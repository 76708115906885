.form {
  margin: 20px 0;
}

.imageUpload {
  text-align: center;
}

.buttonItem {
  text-align: center;
}

.button {
  margin: 30px 0 0 0;
  width: 140px;
}
