.search {
  margin-bottom: 20px;
  width: 550px;
}

.image {
  object-fit: contain;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
