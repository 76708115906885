.login {
  width: 100vw;
  height: 100vh;
  margin: auto;
  display: flex;
  align-items: center;
  background-color: gray;
}

.header {
  text-align: center;
  margin: 10px 0 30px 0;
}

.form {
  margin: auto;
  padding: 50px 30px;
  background-color: white;
  width: 400px;
}

.inputItem {
  width: 100%;
  padding: 0;
}

.buttonItem {
  text-align: center;
}

.button {
  margin: 30px 0 0 0;
  width: 100px;
}
