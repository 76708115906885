.category {
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tabs {
  margin: 0 20px 20px 20px;
}

.search {
  margin-bottom: 20px;
  width: 550px;
}

.spin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.form {
  margin: 50px auto;
  width: 500px;
}

.inputItem {
  width: 100%;
  padding: 0;
}

.buttonItem {
  text-align: center;
}

.button {
  margin: 30px 0 0 0;
  width: 140px;
}
