.header {
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.imageWrapper {
  width: 50px;
  height: 50px;
}

.image {
  object-fit: contain;
}

.row {
  cursor: pointer;
}
